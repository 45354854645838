import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const CREATE_APPOINTMENT_NOTE = gql`
  mutation CreateAppointmentNote($appointmentUid: String!, $note: String!) {
    createAppointmentNote(appointmentUid: $appointmentUid, note: $note) {
      uid
      startTime
      # createdAt
      status
      # comment
      sellerService {
        uid
        name
        durationInMinutes
      }
      buyer {
        uid
        formalName
        phone
        email
      }
      familyMember {
        uid
        formalName
        phone
        relationType
      }
      buyerNote {
        uid
        content
        createdAt
      }
      sellerNote {
        uid
        content
        createdAt
        updatedAt
      }
    }
  }
`;

export const useCreateAppointmentNote = () => {
  const [createAppointmentNoteMutation] = useMutation(CREATE_APPOINTMENT_NOTE); // Destructure error from useMutation

  const handleCreateAppointmentNote = async (input) => {
    try {
      const response = await createAppointmentNoteMutation({
        variables: {
          appointmentUid: input?.appointmentUid,
          note: input?.note,
        },
      });
      return response;
    } catch (err) {
      console.error('Error creating appointment note:', err);

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleCreateAppointmentNote;
};
