import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const SEARCHED_CLIENTS = gql`
  query BuyersSearch($query: String!, $pagination: PaginationInput) {
    buyersSearch(query: $query, pagination: $pagination) {
      nodes {
        uid
        formalName
        email
        phone
      }
      pagination {
        perPage
        totalPages
      }
    }
  }
`;

export const useSearchClients = (input) => {
  const { loading, error, data, refetch } = useQuery(SEARCHED_CLIENTS, {
    variables: input,
    skip: input?.query?.length < 3,
  });

  return { loading, error, data, refetch };
};
