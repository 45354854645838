import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

// Define the GraphQL query
const GET_APPOINTMENTS = gql`
  query AppointmentsList(
    $filter: AppointmentsFilterInput!
    $pagination: PaginationInput!
  ) {
    appointments(
      filter: $filter
      pagination: $pagination
      disablePagination: false
    ) {
      nodes {
        uid
        startTime
        # createdAt
        status
        # comment
        sellerService {
          uid
          name
          durationInMinutes
        }
        buyer {
          formalName
          phone
        }
        familyMember {
          formalName
          phone
          relationType
        }
        buyerNote {
          uid
          content
          createdAt
        }
        sellerNote {
          uid
          content
          createdAt
          updatedAt
        }
      }
      pagination {
        perPage
        lastPage
        nextPage
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

// Custom hook to fetch appointments
export const useGetAppointments = (filter, disablePagination = false) => {
  const { loading, error, data, refetch } = useQuery(GET_APPOINTMENTS, {
    variables: {
      filter: filter?.filter,
      pagination: filter?.pagination,
      disablePagination: disablePagination,
    },
  });

  return { loading, error, data, refetch };
};
