import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
const GET_BUYER_CONTACTS = gql`
  query ListBuyerFamilyMembers($buyerUid: String!) {
    buyerFamilyMembers(buyerUid: $buyerUid) {
      uid
      formalName
      phone
      relationType
    }
  }
`;

export const useGetBuyerContacts = (buyerUid) => {
  const { loading, error, data, refetch } = useQuery(GET_BUYER_CONTACTS, {
    variables: { buyerUid },
  });

  return { loading, error, data, refetch };
};
