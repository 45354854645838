import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

// Define your GraphQL mutation
const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment(
    $buyerUid: String!
    $familyMemberUid: String!
    $input: CreateAppointmentInput!
    $sellerServiceUid: String!
    $note: String!
  ) {
    createAppointment(
      buyerUid: $buyerUid
      familyMemberUid: $familyMemberUid
      input: $input
      sellerServiceUid: $sellerServiceUid
      note: $note
    ) {
      status
    }
  }
`;

// Define your custom hook
export const useCreateAppointment = () => {
  const [createAppointmentMutation] = useMutation(CREATE_APPOINTMENT);

  const handleCreateAppointment = async (
    input,
    sellerServiceUid,
    buyerUid,
    familyMemberUid,
    note
  ) => {
    try {
      const response = await createAppointmentMutation({
        variables: {
          input,
          sellerServiceUid,
          buyerUid,
          familyMemberUid: familyMemberUid || '',
          note,
        },
      });
      return response;
    } catch (err) {
      console.log('Error creating client:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      return null; // Or throw a custom error for further handling
    }
  };

  return handleCreateAppointment;
};
