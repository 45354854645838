import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const UPDATE_APPOINTMENT_NOTE = gql`
  mutation UpdateAppointmentNote(
    $appointmentUid: String!
    $noteUid: String!
    $note: String!
  ) {
    updateAppointmentNote(
      appointmentUid: $appointmentUid
      noteUid: $noteUid
      note: $note
    ) {
      uid
      startTime
      # createdAt
      status
      # comment
      sellerService {
        uid
        name
        durationInMinutes
      }
      buyer {
        uid
        formalName
        phone
        email
      }
      familyMember {
        uid
        formalName
        phone
        relationType
      }
      buyerNote {
        uid
        content
        createdAt
      }
      sellerNote {
        uid
        content
        createdAt
        updatedAt
      }
    }
  }
`;

export const useUpdateAppointmentNote = () => {
  const [updateAppointmentNoteMutation] = useMutation(UPDATE_APPOINTMENT_NOTE); // Destructure error from useMutation

  const handleUpdateAppointmentNote = async (input) => {
    try {
      const response = await updateAppointmentNoteMutation({
        variables: {
          appointmentUid: input?.appointmentUid,
          note: input?.note,
          noteUid: input?.noteUid,
        },
      });
      return response;
    } catch (err) {
      console.error('Error creating appointment note:', err);

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleUpdateAppointmentNote;
};
